import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

// Learnings and tools
import { ReactComponent as DesignIcon } from './svg/design.svg';
import { ReactComponent as ManagementIcon } from './svg/package.svg';
import { ReactComponent as JiraIcon } from './svg/jira.svg';
import { ReactComponent as FigmaIcon } from './svg/figma.svg';
import { ReactComponent as ReactIcon } from './svg/react.svg';
import { ReactComponent as StorybookIcon } from './svg/storybook.svg';
import { ReactComponent as MaterialIcon } from './svg/materialdesign.svg';
import { ReactComponent as MUIIcon } from './svg/mui.svg';
import { ReactComponent as AmplitudeIcon } from './svg/amplitude.svg';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Image from '@common/Image';
import ExternalLink from '@common/ExternalLink';
import DemoLink from '@common/DemoLink';
import { PageSEO } from '@common/SEO';

import {
  Section,
  Container,
  DesignText,
  // TechnologyText,
  // RunningText,
} from '@components/global';

import {
  Content,
  PageTitle,
  Title,
  Paragraph,
  List,
  Item,
  LearningsGrid,
  Learning,
  LearningTopic,
  // Element,
} from '@components/pages';

import Footer from '@sections/Footer';

import meta from './citizen-ux.json';
const { title, description } = meta;

const PortfolioPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        designs: allFile(
          filter: { absolutePath: { regex: "/citizen-ux/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 900, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
        seo: allFile(
          filter: {
            absolutePath: { regex: "/citizen-ux/" }
            name: { eq: "thumbnail" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <PageSEO
          title={title}
          description={description}
          img={data.seo.edges[0].node.childImageSharp.fixed.src}
          location={location}
        />
        <Navbar page />

        <Section>
          <Container>
            <Content>
              <PageTitle>
                <DesignText>{title}</DesignText>
              </PageTitle>
              <Paragraph>{description}</Paragraph>
              <Image
                edges={data.designs.edges}
                name="thumbnail"
                alt="Designed screens for e-government solutions"
                overflow
              />

              <LearningsGrid>
                <Learning>
                  <DesignIcon />
                  <LearningTopic>Product Design</LearningTopic>
                </Learning>
                <Learning>
                  <ManagementIcon />
                  <LearningTopic>Product Management</LearningTopic>
                </Learning>
                <Learning>
                  <FigmaIcon />
                  <LearningTopic>Figma</LearningTopic>
                </Learning>
                <Learning>
                  <ReactIcon />
                  <LearningTopic>React</LearningTopic>
                </Learning>
                <Learning>
                  <JiraIcon />
                  <LearningTopic>Jira Software</LearningTopic>
                </Learning>
                <Learning>
                  <StorybookIcon />
                  <LearningTopic>Storybook</LearningTopic>
                </Learning>
                <Learning>
                  <MaterialIcon />
                  <LearningTopic>Material Design</LearningTopic>
                </Learning>
                <Learning>
                  <MUIIcon />
                  <LearningTopic>Material-UI</LearningTopic>
                </Learning>
                <Learning>
                  <AmplitudeIcon />
                  <LearningTopic>Amplitude</LearningTopic>
                </Learning>
              </LearningsGrid>

              <Title>Takeaways</Title>

              <List>
                <Item>
                  Took the lead of the product's development and strategy as
                  Product Designer and Product Owner
                </Item>
                <Item>
                  Built with a team with a Senior Backend Engineer and 2 Junior
                  Frontend Developers, with the collaboration of 2 Senior
                  Product Managers, and insights from Account Management and
                  Customer Success.
                </Item>
                <Item>
                  Conducted research and discovery to gain buy-in from
                  management into the proposed solution.
                </Item>
                <Item>Prototyped and designed the whole product.</Item>
                <Item>Coded the frontend for the MVP.</Item>
              </List>

              <Title>Problem statement</Title>
              <Paragraph>
                The electronic register is a small but critical product that
                allows citizens to formally submit requests to their city
                council.
              </Paragraph>
              <Paragraph>
                At T-Systems we had such application, used by 150+ local
                governments in Spain and reaching up to 10M citizens. But after
                15 years of development the product was bloated with features,
                technologically old and without any regard for usability.
                Citizen complaints were frequent and abundant.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="1-problem"
                overflow
                alt="Examples of issues and complaints"
                description="Screenshot of the existing product and examples of issues and complaints recorded via customer support"
              />

              <Title>The citizen journey</Title>

              <Paragraph>
                Fixing everything on the existing codebase was not reasonable
                from a business standpoint, making a redesign using a modern
                tech stack the chosen path.
              </Paragraph>

              <Paragraph>
                But building from scratch everything would have been costly and
                would have taken an excessive amount of time.
              </Paragraph>

              <Paragraph>
                To find the least we could implement as a proof of concept and
                later develop a minimum viable product we first mapped the
                entire journey on the product and broke the experience into
                steps.
              </Paragraph>

              <Paragraph>
                We used different discovery methods at this stage.
              </Paragraph>

              <List>
                <Item>
                  User shadowing: we sat down with citizens and saw them use our
                  product (and our competitors', depending on each person's
                  city)
                </Item>
                <Item>
                  Personas: we detailed 3 types of users we wanted to keep in
                  mind when making decisions (a young college-aged person, a
                  liberal professional, and a non tech-saavy senior)
                </Item>
                <Item>
                  Competitive benchmark: we audited all our major competitors,
                  on both their current products and their current developments
                </Item>
                <Item>
                  Similar worlds: we identified applicable patterns on other
                  solutions (eg: shopping checkout)
                </Item>
                <Item>
                  Product personality: we agreed early on to give it a more
                  friendly face and tone (dealing with government doesn't have
                  to feel like going to a funeral!)
                </Item>
                <Item>
                  Creative reframing: for each step of the journey, we worked on
                  a shared understanding of what was the main challenge citizens
                  faced
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="2-user-journey"
                overflow
                alt="User journey"
                description="Mapping the user journey of citizens"
              />

              <Paragraph>
                To try to bridge the gap between the expectations of us, as the
                product developers, and IT teams at City Councils, the product
                customers, we devised surveys to be sent to both collectives but
                analyzed segmented.
              </Paragraph>

              <Paragraph>
                But it not only showed our biases: it also helped reframe ours
                and our customers understanding of end users.
              </Paragraph>

              <Paragraph>
                For example, we all had the same intuition on how much time it
                took for the average citizen to fill a simple form (between 3
                and 5 minutes). But analytics on the product showed it took
                them, on average, double that time.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="3-1-surveys"
                overflow
                alt="Surveys"
                description="Use of surveys to uncover biases and divergence of perceptions between the product team and customers"
              />

              <Paragraph>
                To set some benchmarks on the current product, ad-hoc analytics
                was set up into the app (as that was something the the product
                didn't have) and users were recruited to run experiments as
                citizens using the software.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="3-2-experiments"
                overflow
                alt="Experiments"
                description="Use of experiments to test hypothesis and create benchmarks to measure the new solution against"
              />

              <Title>Product management</Title>
              <Paragraph>
                We used JIRA Software for all our projects at T-Systems. We
                decided to apply agile methodologies and adopted bi-weekly
                sprints.
              </Paragraph>

              <Paragraph>
                The decision to introduce dailies, later on, proved to be the
                most important step to change our working habits from waterfall
                to fast incremental deliveries: we were quickly addressing
                roadblocks early.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="5-product-management"
                overflow
                alt="Product management"
                description="Curating a backlog of tasks in Jira Software and delivering in bi-weekly sprints"
              />

              <Paragraph>
                With a extremely small team, made of just a senior backend
                developer and me as a frontend developer, we delivered the PoC
                and were given the go-ahead to proceed with the project.
              </Paragraph>

              <Paragraph>
                Together with senior product managers from the team, and sales
                and business stakeholders, we prioritized all the necessary
                features we had identified to build after the MVP.
              </Paragraph>

              <List>
                <Item>
                  Using a cost vs value matrix, each of us worked individually
                  to placed all features on the grid.
                </Item>
                <Item>
                  We then joined for a workshop where we discussed any
                  disagreements and settled via consensus.
                </Item>
                <Item>
                  Without previous warning, at the workshop we also introduced a
                  trash where we could throw away features: we got rid of 15% of
                  them, for good.
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="3-3-priorization"
                overflow
                alt="Priorization"
                description="Collaborative priorization (and dismissal) of all existing features and missing ones"
              />

              <Title>Product design</Title>

              <Paragraph>
                Given that a sizeable part of the issues was due to the
                usability of UI elements, we decided to implement a library of
                navigation and form components on a themable design system (
                <Link to="/design/multi-brand-design-system">case study</Link>)
                where each could be developed in isolation and given focused
                care.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="4-design-system"
                overflow
                alt="Design system"
                description="Building and visually testing a Design System to cover all possible form elements needed in Storybook"
              />

              <Paragraph>
                Design work was done on Figma, using a mobile-first approach and
                scaling up components and screens for bigger devices.
              </Paragraph>

              <List>
                <Item>Explorations for new elements</Item>
                <Item>Screen flow prototyping</Item>
                <Item>
                  Motion prototyping (eg: screen transitions,
                  micro-interactions)
                </Item>
                <Item>Responsiveness</Item>
                <Item>Theming</Item>
                <Item>Handover specs for engineering</Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="6-4-exploration"
                overflow
                alt="Exploration"
                description="Example exploration of a document preview component"
              />

              <Image
                edges={data.designs.edges}
                name="6-1-motion"
                overflow
                alt="Motion design"
                description="Motion design prototyping for the transition between the 'submit' screen and a 'transaction in progress' screen"
              />

              <Image
                edges={data.designs.edges}
                name="6-5-responsive"
                overflow
                alt="Responsive design"
                description="Upscaling a product screen from the mobile-first design up to desktop"
              />

              <Image
                edges={data.designs.edges}
                name="6-2-theming"
                overflow
                alt="Theming"
                description="Exploration of theming with different end customer branding and palettes"
              />

              <Image
                edges={data.designs.edges}
                name="6-3-specs"
                overflow
                alt="Design specs"
                description="Example of a finished design artifact to communicate design specs to developers"
              />

              <Image
                edges={data.designs.edges}
                name="barcelona"
                overflow
                alt="Screens for Ajuntament de Barcelona"
                description="Final screens of the product applied to Ajuntament de Barcelona"
              />
            </Content>
            <Paragraph>
              The product was received with high appraisals from all existing
              customers (city councils) it was shown to, with many quickly
              commiting to the beta-testing phase.
            </Paragraph>
          </Container>
        </Section>

        <Footer neutral />
      </Layout>
    )}
  />
);

export default PortfolioPage;
